import React, { FC, useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Link } from 'react-router-dom';
import {
  Datagrid,
  ShowButton,
  Button,
  ReferenceArrayField,
  Show,
  Tab,
  SimpleShowLayout,
  // TabbedShowLayout,
  DateField,
  TextField,
  FileField,
  RecordContextProvider,
  useRecordContext,
  useDataProvider,
  useCreatePath,
  usePermissions,
  Loading,
  Error,
} from 'react-admin';
import Stack from '@mui/material/Stack';
import PeopleIcon from '@mui/icons-material/People';

// pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

type Url = {
  fileUrl: string;
}

const S3Url = (path: string) => {
    const dataProvider = useDataProvider();
    const [url, setUrl] = useState<Url>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    if (!path) return null;
    useEffect(() => {
        dataProvider.getPresignedUrl('download', path)
            .then(({ data }:any) => {
                setUrl(data);
                setLoading(false);
            })
            .catch((error:any) => {
                setError(error);
                setLoading(false);
            })
    }, []);
    if (loading) return <Loading />;
    if (error) return <Error error={error} />;
    return url?.fileUrl ?? null;
};

const PDFReport = () => {
    const createPath = useCreatePath();
    const record = useRecordContext();
    const pdf_url = S3Url(record.url);
    const csv_url = S3Url(record.csv_url);
    const { permissions } = usePermissions();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    if (!pdf_url) return null;
    if (!(typeof pdf_url === 'string')) return pdf_url;
    const csv = (!csv_url || !(typeof csv_url === 'string')) ? '' : (<FileField source="csv_url" title="Report CSV" label={false} download={true} />);


    function onDocumentLoadSuccess({ numPages }:any) {
      setNumPages(numPages);
    }

    return (
      <React.Fragment>
	<Stack spacing={2} direction="column">
	  <Stack spacing={2} direction="row">
	    <span>Next steps: Book a coaching session to analyse your report</span>
	    <Button label="Book Now" component={Link} to={{ pathname: createPath({ resource: `book-coaching/${record.id}`, type: 'list' }) }} variant="contained" startIcon={<PeopleIcon />} />
	  </Stack>
	  <RecordContextProvider value={{ pdf_url: pdf_url, csv_url: csv_url }}>
	    <Stack spacing={2} direction="row">
	      <span>Download Report:</span>
	      <FileField source="pdf_url" title="Report PDF" label={false} download={true} />
	      {permissions === 'admin' && csv}
	    </Stack>
	  </RecordContextProvider>
	  <Document file={pdf_url} onLoadSuccess={onDocumentLoadSuccess}>
	    {Array.from(
	    new Array(numPages),
	    (el, index) => (
	      <Page renderTextLayer={false} renderAnnotationLayer={false}
		key={`page_${index + 1}`}
		pageNumber={index + 1}
	      />
	    ),
	  )}
	  </Document>
	</Stack>
      </React.Fragment>
    );
};


const ReportShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <PDFReport />
    </SimpleShowLayout>
    {/*
    <TabbedShowLayout>
      <Tab label="View Report" >
	<PDFReport />
      </Tab>
      <Tab label="Summary" path="summary">
	<TextField source="id" />
	<TextField source="filename" />
	<TextField source="description" />
	<ReferenceArrayField label="Meetings" reference="meetings" source="meeting_ids">
	  <Datagrid rowClick="show">
	    <TextField source="id" />
	    <TextField source="unique_id" />
	    <DateField source="date_time" showTime />
	    <TextField source="name" />
	    <ShowButton />
	  </Datagrid>
	</ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
    */}
  </Show>
);

export default ReportShow;
