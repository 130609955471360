import React, { FC } from 'react';
import { Card, CardContent } from '@mui/material';
import { Title, useGetOne, useCreatePath } from 'react-admin';
import { InlineWidget } from "react-calendly";
import { useParams } from "react-router-dom";

const UserProfile = () => {
    const createPath = useCreatePath();
    const { report_id } = useParams();
    const { data: user, isLoading, error } = useGetOne('users', { id: 'me' });

    if (isLoading || error || !user) {
      return {
        email: '',
        name: '',
      }
    }
    const report_path = createPath({ resource: 'reports/', id: report_id, type: 'show' });
    return {
      email: user.email,
      name: user.full_name,
      customAnswers: {
        a1: report_id ? `Report: ${location.protocol}//${location.hostname}${report_path}` : '',
      },
    }
};

export const BookCoaching: FC = () => (
    <Card>
        <Title title="Book Coaching" />
      <InlineWidget 
        url="https://calendly.com/mary_jo/30min" 
      styles={{
        height: '900px'
      }}
      prefill={
        {...UserProfile()}
      }
      />
    </Card>
);

