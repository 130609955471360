import React, { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Title, } from 'react-admin';

export const Privacy: FC = () => {
  const clauses = [
    'All information is stored securely',
    'Meeting content is not analysed – we only analyse who is speaking.',
    'All recordings are deleted after processing.',
    'We do not share your data with any 3rd parties',
    'You can delete your personal data, (including meetings and reports) at any time.',
    'Data Subject rights:',
    'The European Union’s General Data Protection Regulation (GDPR) and other countries’ privacy laws provide certain rights for data subjects. Data Subject rights under GDPR include the following:',

    'Right to be informed',
    'Right of access',
    'Right to rectification',
    'Right to erasure',
    'Right to restrict processing',
    'Right of data portability',
    'Right to object',
    'Rights related to automated decision making including profiling',
'',
'',
'',
'This Privacy Notice is intended to provide you with information about what personal data Yakbit Ltd. collects about you and how it is used. ',
'',
'If you wish to confirm that Yakbit Ltd. is processing your personal data, or to have access to the personal data Yakbit Ltd. may have about you, please contact us.',
'',
'You may also request information about: the purpose of the processing; the categories of personal data concerned; who else outside Yakbit Ltd. might have received the data from Yakbit Ltd.; what the source of the information was (if you didn’t provide it directly to Yakbit Ltd.); and how long it will be stored. You have a right to correct (rectify) the record of your personal data maintained by Yakbit Ltd. if it is inaccurate. You may request that Yakbit Ltd. erase that data or cease processing it, subject to certain exceptions. You may also request that Yakbit Ltd. cease using your data for direct marketing purposes. In many countries, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how Yakbit Ltd. processes your personal data. When technically feasible, Yakbit Ltd. will—at your request—provide your personal data to you.',
'',
'Reasonable access to your personal data will be provided at no cost. If access cannot be provided within a reasonable time frame, Yakbit Ltd. will provide you with a date when the information will be provided. If for some reason access is denied, Yakbit Ltd. will provide an explanation as to why access has been denied.',
'',
'For questions or complaints concerning the processing of your personal data, you can email us at hello@yakbit.ai. Alternatively, if you are located in the European Union, you can also have recourse to the European Data Protection Supervisor or with your nation’s data protection authority.',
  ];
  const listItems = clauses.map((clause,index) => <li key={"clause"+index}><Typography variant="body2">{clause}</Typography></li>);
  return (
    <Card>
      <Title title="Privacy Statement" />
      <CardContent>
        <Typography variant="h4">
        Privacy Statement
        </Typography>
        <ul>{listItems}</ul>
      </CardContent>
    </Card>
  )
};
