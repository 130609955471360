import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

export function AltTextField({ ...props }:any) {
  const record = useRecordContext();
  for (const source of props.sources) {
    if (record[source]) {
      return <TextField source={source} {...props} />;
    }
  }
  return null;
}

