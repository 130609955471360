import React, { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Title, } from 'react-admin';

export const Terms: FC = () => {
  const clauses = [
    'Description of Service and Usage Limitation',
    ' - This product is provided for use in a commercial setting, and offers analysis of meetings to provide coaching and training for meeting leaders.',
    'Links to Privacy Policy',
    ' - Our Privacy Policy can be reached via the link at the bottom of the page',
    'Description of Expected Procedures and Liability',
    ' - Yakbit aim to ensure this service is available whenever you need it, but we make no guarantees relating to uptime.',
    'Description of Payment Policy',
    ' - Any subscriptions or payments made are governed by the individual contracts agreed at the time of commencing your agreement with us.',
  ];
  const listItems = clauses.map((clause,index) => <li key={"clause"+index}><Typography variant="body2">{clause}</Typography></li>);
  return (
    <Card>
      <Title title="Terms of Use" />
      <CardContent>
        <Typography variant="h4">
        Terms of Use
        </Typography>
        <ul>{listItems}</ul>
      </CardContent>
    </Card>
  )
};

