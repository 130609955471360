import * as React from 'react';
import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    Form,
    required,
    useTranslate,
    useNotify,
    useSafeSetState,
    useAuthProvider,
    useNotificationContext,
} from 'ra-core';
import { TextInput } from 'react-admin';

export const PasswordRecovery = (props: LoginFormProps) => {
    const { redirectTo, className } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const authProvider = useAuthProvider();
    const navigate = useNavigate();
    const { resetNotifications } = useNotificationContext();

    const recoverPassword = useCallback(
        (params: any = {}, pathName) =>
            authProvider.recoverPassword(params).then(ret => {
                resetNotifications();
                navigate('/password-recovery-sent');
                return ret;
            }),
        [
            authProvider,
            navigate,
            resetNotifications,
        ]
    );

    const submit = (values: FormData) => {
        setLoading(true);
        recoverPassword(values, redirectTo)
            .then(({ msg }) => {
                setLoading(false);
                notify(msg);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    }
                );
            });
    };

    return (
        <StyledForm
            onSubmit={submit}
            mode="onChange"
            noValidate
            className={className}
        >
            <CardContent className={LoginFormClasses.content}>
                <Typography 
                    className={LoginFormClasses.header}
                    variant="h6" 
                    gutterBottom
                >
                  Recover Password
                </Typography>
                <TextInput
                    autoFocus
                    source="username"
                    label={translate('Email')}
                    autoComplete="username"
                    validate={required()}
                    fullWidth
                />

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginFormClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        'Recover'
                    )}
                </Button>
            </CardContent>
        </StyledForm>
    );
};

export const PasswordRecoverySent = () => {
    return (
        <StyledForm>
            <CardContent className={LoginFormClasses.content}>
                <Typography 
                    className={LoginFormClasses.header}
                    variant="h6" 
                    gutterBottom
                >
                  Password Recovery Sent
                </Typography>
                <Typography 
                    className={LoginFormClasses.header}
                    variant="body1" 
                    gutterBottom
                >
                  If the email address provided matches a current user, you will receive an email. Please follow the instructions in the email to reset your password.
                </Typography>
            </CardContent>
        </StyledForm>
    );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
    header: `${PREFIX}-header`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.header}`]: {
        textAlign: 'center',
    },
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

export interface LoginFormProps {
    redirectTo?: string;
    className?: string;
}

interface FormData {
    username: string;
    password: string;
}
PasswordRecovery.propTypes = {
    redirectTo: PropTypes.string,
};
