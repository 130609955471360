import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import ReportShow from './ReportShow';
import ReportList from './ReportList';

export default {
  show: ReportShow,
  list: ReportList,
  icon: TrendingUpIcon
};

