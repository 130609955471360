import EventIcon from '@mui/icons-material/Event';

// import RecallEdit from './RecallEdit';
import RecallList from './RecallList';
import RecallCreate from './RecallCreate';

export default {
  create: RecallCreate,
  // edit: RecallEdit,
  list: RecallList,
  icon: EventIcon
};

