import React, { FC } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Title, useDataProvider, useRedirect } from 'react-admin';

export const Settings: FC = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const redirectToZoomOAuth = async () => {
    const { data, isLoading, error } = await dataProvider.getRecallOAuthZoomUrl();
    const { url } = data;
    redirect(url);
  }
  return (
    <Card>
      <Title title="Settings" />
      <CardContent>
        <Typography variant="h4">
        Settings
        </Typography>
        <Button 
          variant="contained"
          onClick={() => { redirectToZoomOAuth(); }}
        >Connect Zoom Account</Button>
      </CardContent>
    </Card>
  )
};

