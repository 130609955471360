import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
} from 'react-admin';

const RecallCreate: FC = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: any) => {
    // notify('Bot sent to meeting. Please allow the bot to enter and record the meeting. Once the meeting is complete, please book a session to discuss your results.', { autoHideDuration: 0 });
    notify('Bot sent to meeting. Please allow the bot to enter and record the meeting. Once the meeting is complete, please book a session to discuss your results.', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 60000, multiLine: true, type: 'success' });
    redirect('/book-coaching');
  };

  return (
    <Create mutationOptions={{ onSuccess }} {...props}>
      <SimpleForm>
        <TextInput source="url" />
      </SimpleForm>
    </Create>
  );
}

export default RecallCreate;

