import { Theme, DarkTheme } from './Theme';
import Layout from './Layout';
import AppBar from './AppBar';
import { Footer, LoginFooter} from './Footer';

export default {
  theme: Theme,
  darkTheme: DarkTheme,
  layout: Layout
};
export { AppBar, Footer, LoginFooter };

