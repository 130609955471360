import React, { Children } from 'react';
import { Layout as RALayout} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { Footer } from './Footer';
import { Box, BoxProps, Stack } from '@mui/material';

const Layout = (props: any) => {
    const {
        children,
        ...rest
    } = props;
    const arrayChildren = Children.toArray(children);
    const newChildren = [...arrayChildren , <Footer key="footer"/>];
    return (
        <RALayout 
            {...rest} 
            children={newChildren} 
            appBar={AppBar} 
            menu={Menu} 
            sx={{
                '& .RaLayout-content': {
                    justifyContent: "space-between",
                },
            }}
        />
    );
};

export default Layout;
