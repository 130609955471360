import React, { FC } from 'react';
import {
  Datagrid,
  DateField,
  ShowButton,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  BooleanField,
  EmailField,
  ChipField,
} from 'react-admin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const UserShow: FC = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Summary">
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="full_name" />
        <BooleanField source="is_superuser" label="Yakbit Admin" />
        <BooleanField source="is_active" label="Enabled" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Meetings" path="meetings">
        <ReferenceManyField
          addLabel={false}
          reference="meetings"
          target="owner_id"
          sort={{ field: 'date_time', order: 'DESC' }}
        >
          <Datagrid>
            <TextField source="id" />
            <TextField source="unique_id" />
            <DateField source="date_time" showTime />
            <TextField source="status" />
            <TextField source="name" />
            <ReferenceField
              resource="meetings"
              source="report_id"
              reference="reports"
              link="show"
            >
              <ChipField source="filename" label="label" icon={<TrendingUpIcon/>} />
            </ReferenceField>
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserShow;
