import React, { FC } from 'react';
import { AppBar, useCreatePath } from 'react-admin';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from './GTI-ERDF-logo.png';

type ImgProps = {
    alt: string;
    src: string;
    // add more HTML img attributes you need
}

const Img = (props: BoxProps & ImgProps) => (
	<Box component='img' {...props} />
);

const Footer: FC = props => {
    const createPath = useCreatePath();
    return (
      <Typography variant="caption">
      <Link to={createPath({ resource: 'privacy', type: 'list' })}>Privacy Policy:</Link>
      </Typography>
    )
};

const LoginFooter: FC = props => {
    return (
    <Stack 
      justifyContent="space-evenly" 
      alignItems={{ xs: 'center', sm: 'flex-end' }}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      // style={{ position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100 }}
    >
      <Box>
        <Img sx={{ height: 100 }}
             src={logo} alt="GTI and ERDF logo" />
      </Box>
      <Box style={{ fontSize: 14, textAlign: 'center', margin: 20 }}>
        This web application has been developed through the New Anglia Growth Through Innovation Fund, which is part-funded by the European Regional Development Fund. 
      </Box>
    </Stack>
    )
};

export { Footer, LoginFooter };
