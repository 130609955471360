import ParaglidingIcon from '@mui/icons-material/Paragliding';

import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';

export default {
  create: UserCreate,
  edit: UserEdit,
  list: UserList,
  show: UserShow,
  icon: ParaglidingIcon 
};
