import React, { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ReferenceArrayField,
  ShowButton,
  SingleFieldList,
  ChipField,
  usePermissions,
} from 'react-admin';
import { AltTextField } from '../Components';
import EventIcon from '@mui/icons-material/Event';

const ReportList: FC = (props) => {
  const { permissions } = usePermissions();
  return (
    <List {...props}>
      <Datagrid rowClick="show">
	<TextField source="id" />
	<TextField source="filename" />
	<TextField source="description" />
	<ReferenceArrayField label="Meetings" reference="meetings" source="meeting_ids">
	  <SingleFieldList>
	    <ChipField source="unique_id" icon={<EventIcon/>} />
	  </SingleFieldList>
	</ReferenceArrayField>
	{permissions === 'admin' && 
	  <ReferenceField
	    resource="reports"
	    source="owner_id"
	    reference="users"
	    link="edit"
	  >
	    <AltTextField sources={["full_name", "email"]} />
	  </ReferenceField>
	}
	<ShowButton />
      </Datagrid>
    </List>
  )
};

export default ReportList;
