import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress, Typography } from '@mui/material';
import {
    Form,
    required,
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
      useCreatePath,
} from 'ra-core';
import { TextInput } from 'react-admin';

export const LoginForm = (props: LoginFormProps) => {
    const { redirectTo, className } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const createPath = useCreatePath();

    const submit = (values: FormData) => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    }
                );
            });
    };

    return (
        <StyledForm
            onSubmit={submit}
            mode="onChange"
            noValidate
            className={className}
        >
            <CardContent className={LoginFormClasses.content}>
                <Typography 
                    className={LoginFormClasses.header}
                    variant="h6" 
                    gutterBottom
                >
                  Login to Yakbit
                </Typography>
                <TextInput
                    autoFocus
                    source="username"
                    label={translate('Email')}
                    autoComplete="username"
                    validate={required()}
                    fullWidth
                />
                <TextInput
                    source="password"
                    label={translate('ra.auth.password')}
                    type="password"
                    autoComplete="current-password"
                    validate={required()}
                    fullWidth
                />

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginFormClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        translate('ra.auth.sign_in')
                    )}
                </Button>
                <Button
                    variant="contained"
                    type="button"
                    component={Link} 
                    to={{ 
                        pathname: createPath({ 
                            resource: '/password-recovery', 
                            type: 'list' 
                        }) 
                    }} 
                    color="secondary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    Forgot Password
                </Button>
                <Button
                    variant="contained"
                    type="button"
                    component={Link} 
                    to="https://yakbit.ai/get-in-touch/"
                    color="secondary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    Request Access
                </Button>
            </CardContent>
        </StyledForm>
    );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
    header: `${PREFIX}-header`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.header}`]: {
        textAlign: 'center',
    },
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

export interface LoginFormProps {
    redirectTo?: string;
    className?: string;
}

interface FormData {
    username: string;
    password: string;
}
LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};
