import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppBar as RAAppBar, AppBarProps as RAAppBarProps } from 'react-admin';
import Box, {BoxProps} from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import logo from './logo.png';

const useStyles = makeStyles()({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});
 
 
type ImgProps = {
    alt: string;
    src: string;
    // add more HTML img attributes you need
}

const Img = (props: BoxProps & ImgProps) => (
	<Box component='img' {...props} />
);

type RAAppBarProps = typeof RAAppBarProps

const AppBar: FC<RAAppBarProps> = props => {
    const { classes } = useStyles();
    return (
        <RAAppBar color='primary' {...props}>
            <Link to="https://yakbit.ai/">
                <Img sx={{ height: 32, mt: 1.0, mb: 0.5, mx: 2 }}
                    src={logo} alt="Yakbit" />
            </Link>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
        </RAAppBar>
    );
};

export default AppBar;
