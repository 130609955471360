import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  BooleanInput,
} from 'react-admin';

const UserEdit: FC = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="email" />
      <TextInput source="full_name" />
      <PasswordInput source="password" />
      <BooleanInput source="is_superuser" label="Yakbit Admin" />
      <BooleanInput source="is_active" label="Enabled" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
