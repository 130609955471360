import { defaultTheme, defaultDarkTheme } from 'react-admin';
import merge from 'lodash/merge';

const BaseTheme = {
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    components: {
        RaLayout: { // override the styles of all instances of this component
            styleOverrides: {
                root: { // Name of the rule
                    // marginBottom: 100,
                },
            },
        },
        MuiButton: { // override the styles of all instances of this component
            styleOverrides: {
                root: { // Name of the rule
                    borderRadius: 18,
                },
            },
        },
    },
};

const LightPalette = ({
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fff"
        },
        secondary: {
            light: "#f8f8f8",
            main: "#f2f2f2",
            dark: "#e0e0e0",
            contrastText: "#000"
        },
        primary: {
            light: "#565656",
            main: "#4b4b4b",
            dark: "#252525",
            contrastText: "#fff"
        },
        error: {
            light: "#EB715E",
            main: "#E16754",
            dark: "#CD5340",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(155, 155, 155, 1)"
        }
    },
});

const DarkPalette = ({
    palette: {
        background: {
            paper: "#212121",
            default: "#313131"
        },
        primary: {
            light: "#f8f8f8",
            main: "#e0e0e0",
            dark: "#808080",
            contrastText: "#000"
        },
        secondary: {
            light: "#565656",
            main: "#4b4b4b",
            dark: "#252525",
            contrastText: "#fff"
        },
        error: {
            light: "#EB715E",
            main: "#E16754",
            dark: "#CD5340",
            contrastText: "#fff"
        },
    },
    components: {
        MuiSwitch: { // override the styles of all instances of this component
            styleOverrides: {
                switchBase: { // Name of the rule
                    color: "#4b4b4b",
                },
            },
        },
    },
});

export const Theme = merge({}, defaultTheme, BaseTheme, LightPalette)
export const DarkTheme = merge({}, defaultDarkTheme, BaseTheme, DarkPalette);
