import React, { FC } from 'react';
import { useList, ListContextProvider, Datagrid, DateField, List, ReferenceField, TextField, UrlField } from 'react-admin';
import { Empty } from '../Components';

const data:Array<any> = [];

export const RecallList = () => {
    const listContext = useList({ data });
    return (
        <ListContextProvider
          value={listContext}
          title="Meetings"
        >
          <Empty buttonLabel="Add Meeting Link"/>
        </ListContextProvider>
    );
};


    {/*
export const RecallList = () => {
    const listContext = useList({ data });
    return (
        <ListContextProvider
          empty={<Empty buttonLabel="Add Meeting Link"/>} 
          value={listContext}
        >
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
            </Datagrid>
        </ListContextProvider>
    );
};



export const RecallList = () => (
    <List
      empty={<Empty buttonLabel="Add Meeting Link"/>} 
    >
        <Datagrid rowClick="edit">
            <UrlField source="url" />
            <TextField source="id" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <TextField source="bot_id" />
            <TextField source="platform" />
            <ReferenceField source="owner_id" reference="users" />
            <TextField source="owner.email" />
        </Datagrid>
    </List>
);
    */}

export default RecallList;
