// in src/users.js
import React, { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  EditButton,
  ShowButton,
} from 'react-admin';

const UserList: FC = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="full_name" />
      <BooleanField source="is_superuser" label="Yakbit Admin" />
      <BooleanField source="is_active" label="Enabled" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default UserList;
